$primaryColor: #1a73e8;
$white: #fff;

#client_name_tooltip,
#legal_name_tooltip,
#legal_entity_tooltip,
deloitteai-contact-banner,
brain-asset-details-purchase .asset-container p {
    display: none !important;
}

.gcloudai-theme {
    background: #fff !important;

    aihub-loader {
        .loader-overlay .loader:before {
            background-color: #cfcfcf !important;
        }
    }

    brain-global-header>div {
        border-bottom: 0 !important;
    }

    .navbar {
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        background: #fff;
        height: 4.37rem;

        .nav-item:hover {
            background: transparent;
        }

        .header-logo {
            height: 29px !important;
            width: 100% !important;
            margin-left: 25px;
        }
    }

    .shared-header .mat-menu-trigger.active {
        background: transparent !important;
    }

    .bg-black,
    .catalogue__header,
    .brain-asset-detail .header {
        background: #fff !important;
    }

    .autocomplete-search-wrapper {
        border: 1px solid #1f82c0 !important;
    }

    brain-global-header {
        .tile {
            box-shadow: none;
        }

        .container {
            margin: 0 auto !important;
        }

        .asset-container {
            padding: 0 0% !important;
        }
    }

    .catalogue__header--title,
    .catalogue__header--subtitle,
    .brain-asset-detail .header-title,
    .brain-asset-detail .header-subtitle,
    .brain-asset-detail .header aihub-breadcrumb .list.breadcrumb-list .breadcrumb-list--item a.router-link-active {
        color: #000 !important;

        p>img {
            display: none;
        }
    }

    .catalogue__header {
        padding-bottom: 0px !important;
    }

    .border-green {
        border-bottom: 1px solid transparent !important;
    }

    .deloitteai--header__list .counter {
        background-color: transparent !important;
        border: 1px solid #000 !important;
    }

    .btn.btn-primary,
    aihub-login-page .input-container button {
        color: $white !important;
        background-color: $primaryColor !important;
        border-radius: 2rem !important;
        padding: 1rem 2rem !important;

        &:disabled {
            opacity: 0.5 !important;
        }
    }

    asset-owner--menu li .label:before,
    .deloitteai__header--usermenu .user {
        color: #fff !important;
        background-color: #1a73e8 !important;
    }

    .aihub-tooltip mat-icon,
    .badge.success,
    .btn.btn-secondary,
    a,
    .deloitteai--header__list .counter,
    .available-items .mat-chip {
        color: #000 !important;
    }

    footer {
        background-color: #fff;
        border-top: 1px solid #ccc;

        a {
            font-weight: normal;
        }
    }

    .deloitteai__header--usermenu ul {
        background: #fff;
    }

    .hamburger-logo {
        width: 7.75rem;
        margin-right: 2rem;
    }

    .filter-tabs-wrapper {
        margin-bottom: 0 !important;
    }

    .catalogue__header#deloitteAI {
        // justify-content: space-between !important;
        padding: 0% !important;
    }

    .catalog_image img {
        max-height: 15rem !important;
        max-width: 400px;
    }

    @media screen and (min-width: 768px) and (max-width: 1024px) {
        .catalog_image img {
            max-width: 370px;
        }
    }

    @media screen and (max-width: 768px) {
        .asset-container {
            padding: 0 3% !important;
        }

        .catalog_image img {
            max-width: 320px;
        }

        .catalog-items.row {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }

        .navbar .header-logo {
            max-width: 200px;
        }

        .catalogue__header--title {
            font-size: 1.1rem !important;
        }

        .catalogue__header--subtitle {
            font-size: 0.8rem !important;
            width: 100% !important;
        }

        .autocomplete-search-wrapper {
            margin-right: 15px !important;
            margin-left: 15px !important;
        }
    }

    .shared-header .navbar-left {
        flex: 0 0 300px !important;
    }

    brain-global-header .tile {
        padding-top: 20px;
    }

    .deloitteai__banner {
        min-height: 14rem !important;
    }

    deloitteai-contact-banner .content__links>:first-child {
        display: none !important;
    }

    .autocomplete-search-wrapper .btn {
        border: 0 !important;
    }

    .hamburger-logo {
        max-width: 40px;
    }

    .rounded .hamburger-logo {
        width: 40px;
        border-radius: 50%;
        margin-right: 20px;
    }

    .filter-tabs-wrapper {
        height: 50px;
    }

    p.plan-name {
        display: block !important;
    }
}
